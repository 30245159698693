import { DataService } from "@/utils/dataService";


async function adminLogin(data){
    return await DataService.post('login',data)
}


async function vendorLogin(data){
    
    return await DataService.post('vendor/login',data)

}
//will have vendor login here

export {
    adminLogin,
    vendorLogin
}