export const state = {
    seleted:null
};

export const mutations = {
    SELECT_USER(state, user) {
        state.seleted = user;
    },
    CLEAR_SELECTED_USER(state) {
        state.seleted = null;
    },
};

export const actions = {
    selectNormalUser({ commit }, user) {
        commit('SELECT_USER', user);
    },
    clearSeletedUser({commit}){
        commit('CLEAR_SELECTED_USER'); 
    }
};
