import store from '@/state/store'
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.state.auth.token) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
            adminOnly:false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
            adminOnly:false,
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
            adminOnly:false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                next({ name: 'login' })
            },
            adminOnly:false,
        },
    },
    {
        path: '/programs/programsList',
        name: 'programs',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/programs/programsList')
    },
    {
        path: '/programs/addProgramms',
        name: 'programs',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/programs/addProgramms')
    },
    {
        path: '/programs/editProgramms',
        name: 'editProgramms',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/programs/editProgramms')
    },

    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true, adminOnly:false, },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true, adminOnly:false, },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true, adminOnly:false, },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true, adminOnly:false, },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },

    {
        path: '/users/normal',
        name: 'Normal Users',
        meta: { authRequired: true, adminOnly:false, },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/users/normal/view',
        name: 'Normal Users details',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/users/details/index'),
        redirect: '/users/normal/view/overview',
        children: [
            {
              name: 'Normal User Overview',
              path: 'overview',
              component: () => import('../views/pages/users/details/overview'),
            },
            {
                name: 'Normal User Followers',
                path: 'followers',
                component: () => import('../views/pages/users/details/followers'),
            },
            {
                name: 'Normal User Following',
                path: 'following',
                component: () => import('../views/pages/users/details/following'),
            },
            {
                name: 'Normal User Likes',
                path: 'likes',
                component: () => import('../views/pages/users/details/likes'),
            },
            {
                name: 'Normal User Posts',
                path: 'posts',
                component: () => import('../views/pages/users/details/posts'),
            },
            {
                name: 'Normal User Blocked',
                path: 'blocked',
                component: () => import('../views/pages/users/details/blocked'),
            },
            {
                name: 'Normal User Settings',
                path: 'settings',
                component: () => import('../views/pages/users/details/settings'),
            },
        ]
    },
    {
        path: '/users/admin',
        name: 'Admin Users',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/admins/index')
    },
    {
        path: '/users/vendor',
        name: 'Vendor Users',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/vendors/index')
    },
    {
        path: '/tribes',
        name: 'Tribes',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/tribes/index')
    },
    {
        path: '/tribes/add',
        name: 'Tribes Add',
        meta: { authRequired: true, adminOnly:true, },
        component: () => import('../views/pages/tribes/add')
    },
    {
        path: '/tribes/editTribe',
        name: 'editTribe',
        meta: { authRequired: true ,adminOnly:true,},
        component: () => import('../views/pages/tribes/editTribe')
    },
    {
        path: '/booking',
        name: 'booking',
        meta: { authRequired: true ,adminOnly:true,},
        component: () => import('../views/pages/booking/index')
    },
    
    
    
]
