function setUserType(type){
    localStorage.setItem('user-type', type);
}

function clearUserType(){
    localStorage.removeItem('user-type');
}

function getUserType(){
    return localStorage.getItem('user-type');
}

export {
    setUserType,
    getUserType,
    clearUserType
}